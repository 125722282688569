.breadcrums{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #5e5e5e;
    font-size: 16px;
    margin: 40px 170px;
    text-transform: capitalize;
    font-weight: 600;
}



@media (max-width:1280px){
    .breadcrums{
            margin: 30px 30px;
        font-size: 14px;
    }


}


@media (max-width:1024px){
    .breadcrums{
            margin: 20px 70px;
        font-size: 13px;
    }


}


@media (max-width:800px){
    .breadcrums{
            margin: 20px 30px;
        font-size: 11px;
    }


}



@media (max-width:500px){
    .breadcrums{
        font-size: 9px;
    }


}

