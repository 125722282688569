.item{
    width: 350px;
    transition: 0.5s;

}
.item img{
    border-radius: 6px;
}

.item p{
    margin: 6px 0;
}

.item-prices{
    display: flex;
    align-items: center;
    gap: 20px;
}

.item-new-price{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-old-price{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 400;
    text-decoration: line-through;
}
.item:hover{
    transform: scale(1.05);
    transition: 0.5s;
}

