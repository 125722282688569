.footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  
  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .footer-logo p {
    font-size: 42px;
    color: #464646;
    font-weight: 600;
  }
  
  .footer-links {
    display: flex;
    align-items: center;
    gap: 40px;
    list-style: none;
    color: #252525;
    font-size: 20px;
  }
  
  .footer-links li {
    cursor: pointer;
  }
  .footer-social-icon {
    display: flex;
    gap: 20px;
  }
  
  .footer-icon-container{
      padding: 10px;
      background: #f3f3f3;
      padding-bottom: 6px;
  }
  
  .footer-copy-right{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin-bottom: 20px;
      color: #6f6f6f;
      font-size: 16px;
  
  }
  
  .footer-copy-right hr{
      width: 80%;
      margin: auto;
      height: 1px;
      border: none;
      background: #b1b1b1;
  
  }
  
  