.hero{
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, #fde1ff, #e1ffea22 100%);
    display: flex;
    align-items: center;
  
}

.hero-left{
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-left: 180px;
    line-height: 1.1;
}

.hero-left h2{
    font-size: 30px;
    color: #090909;
    font-weight: 600;
}


.hero-left p{
    font-size: 90px;
    color: #171717;
    font-weight: 700;
}


.hero-hand-icon{
    display: flex;
    align-items: center;
    gap: 20px;
}

.hero-hand-icon img{
    width: 80px;
}

.hero-latest-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 300px;
    height: 60px;
    border-radius: 60px;
    background: #ff4141;
    margin: 20px 0;
    color: #fff;
    cursor: pointer;
    font-size: 21px;
}


.hero-right{
    flex: 1;
    display: flex;
    align-items: center;
}
