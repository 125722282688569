.newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(180deg, #f9d9f8, #e1ffea22 100%);
    width: 78%;
    margin: auto;
    margin-bottom: 60px;
    box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.2);
    padding: 0px 100px;
    height: 38vh;
    gap: 10px;
  }
  
  .newsletter h1 {
    color: #454545;
    font-size: 48px;
    font-weight: 600;
  }
  
  .newsletter p {
    color: #454545;
    font-size: 20px;
  }
  
  .newsletter div{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 700px;
  height: 70px;
  border-radius: 60px;
  border: 1px solid #f9d9f8;
  margin-top: 20px;
  }
  
  .newsletter div input{
      outline: none;
      border: none;
      flex: 1;
      padding-left: 20px;
      background: transparent;
      font-size: 18px;
  
      }
      
      .newsletter div button{
        width: 170px;
        height: 100%;
        border-radius: 60px;
        outline: none;
        border: none;
        background: #ff4141;
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
  
          }
  
  
  
  