.loginsignup{
    width: 100%;
    height: 80vh;
    background: #fce3fe;
    padding-top: 100px;
    padding-bottom: 50px;
}

.loginsignup-container{
    width: 580px;
    height: auto;
    background: white;
    margin: auto;
    padding: 40px 60px;

}

.loginsignup-container h1{
    margin: 20px 0;
}

.loginsignup-fields{
    display: flex;
    flex-direction: column;
    gap: 29px;
    margin-top: 30px;
    }

.loginsignup-fields input{
    height: 55px;
    width: 100%;
    padding: 0 10px;
    border-radius: 0px;
    border: none;
    outline: none;
    border: 1px solid #d8d8d8;
    font-size: 17px;

}    

.loginsignup-fields input:focus{
    border: 1px solid #4a4a4a;
}    
.loginsignup button{
    width: 100%;
    height: 50px;
    background: #222222;
    color: #ffffff;
    margin-top: 30px;
    border: none;
    font-size: 21px;
    font-weight: 500;   
    cursor: pointer;
}

.logisignup-login{
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #4a4a4a;
    margin: 10px 0;
}

.logisignup-login span{
font-weight: 300;
color: orangered;
margin-left: 10px;
cursor: pointer;
}

.loginsignup-agree{
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    color: #4a4a4a;

}


@media (max-width:1280px){


    .loginsignup{
        width: 100%;
        height: auto;
        padding-top: 60px;
        margin-bottom: 30px;
    }

    .loginsignup-container{
        width: auto;
        height: auto;
        margin: auto;
        padding: 20px 30px;
    
    }

    .loginsignup-fields input{
        height: 55px;
        width: auto;
        font-size: 15px;
    } 
    .loginsignup-container h1 {
        font-size: 21px;
        margin: 0 0 ;
    }
    .loginsignup button{
        height: 40px;
     margin-top: 20px;
        font-size: 16px;

    }


    .logisignup-login {
         font-size: 14px;
         display: flex;
         flex-direction: column;
    }
    .loginsignup-agree p{
        font-size: 14px;
    }


    .loginsignup-fields{
        gap: 20px;
        margin-top: 20px;
        }
}






@media (max-width:1280px){

    .loginsignup-container{
          padding: 20px 30px;
    }

    .loginsignup-container h1 {
        font-size: 20px;
        margin: 0 0 ;
    }


    .loginsignup button{
        height: 40px;
         margin-top: 20px;
        font-size: 16px;
    }


    .loginsignup-fields{
        margin-top: 20px;
        }



        .loginsignup-fields input{
            height: 40px;
        
        } 

}


