.relatedproduct{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 80vh;

}

.relatedproduct h1{
    color: #171717;
    font-size: 40px;
    font-weight: 600;
}

.relatedproduct hr{
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}

.relatedproduct-items{
    display: flex;
        gap: 30px;
    margin-top: 30px;
    }


    @media(max-width:1280px){

        .relatedproduct{
            height: 60vh;
        }



    }


    @media(max-width:800px){

        .relatedproduct{
            height: auto;
            margin-bottom: 40px;
        }
        .relatedproduct h1 {
            color: #171717;
            font-size: 30px;
            font-weight: 600;
        }

        .relatedproduct-items{
            display: grid;
            grid-template-columns: 1fr 1fr;
   
            }

            .relatedproduct-items .item{
                    width: 180px;
                }

                .relatedproduct-items .item img{
                    width: 180px;
                }

                .relatedproduct hr{
                    width: 120px;
                    height: 2px;
         
                }
        
    }