.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
    box-shadow: 0 1px 2px -2px black;
}

.navbar .nav-logo{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
} 

.navbar .nav-logo p{
  font-size: 38px;
  color: #171717;
  font-weight: 600;
} 


#nav-menu{
    display: flex;
    align-items: center;
    gap: 45px;
    text-decoration: none;
    list-style: none;
    font-size: 19px;
    color: #646464;
    font-weight: 500;

}


#nav-menu li{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}


#nav-menu li a{
    text-decoration: none;

  }



#nav-menu li hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background-color: #FF4141;
}


.nav-login-cart{
    display: flex;
    align-items: center;
    gap: 30px;
    position: relative;
}

.nav-login-cart button{
    background: #fff;
    border-radius: 75px;
    padding: 10px 24px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #666;
    border: 1px solid #7a7a7a;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s;
}

.nav-login-cart button:active{
    background: #FF4141;
    color: #fff;
    border: 1px solid #FF4141;

}

.nav-cart-count{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    background: #FF4141;
    border-radius: 50%;
    right: -10px;
    top: -5px;
    color: #fff;
}

