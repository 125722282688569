.offers{
    display: flex;
    align-items: center;
    justify-content:space-between ;
    background: linear-gradient(180deg, #f9d9f8, #e1ffea22 100%);
    width: 78%;
    margin: auto;
    padding: 0 100px;
    margin-bottom: 60px;
    box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.2);
}


.offers-left{
    flex: 1;
    display: flex; 
    flex-direction: column;
    justify-content: center;
}

.offers-right{
    flex: 1;
    display: flex; 
    align-items: center;
    justify-content: center;
    
}

.offers-left h1{
    font-size: 60px;
    font-weight: 700;
    color:#3c3c3c;
}

.offers-left p{
    font-size: 21px;
    font-weight: 400;
    margin: 10px 0;
    color:#3c3c3c;
}

.offers-left button{
    width: 200px;
    outline: none;
    border: none;
    padding: 10px 20px;
    font-size: 21px;
    border-radius: 30px;
    background: #ff4141;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;

}






