.productdisplay {
    display: flex;
    margin: 0 170px;
    margin-bottom: 30px;
  }
  
  .productdisplay-left {
    display: flex;
    gap: 17px;
  }
  .productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .productdisplay-img-list img {
    height: 150px;
  }
  .productdisplay-big-img {
    width: 542px;
  }
  
  .productdisplay-right {
    margin: 0 50px;
    display: flex;
    flex-direction: column;
  }
  
  .productdisplay-right h1 {
    font-size: 40px;
    color: #3d3d3d;
    font-weight: 700;
  }
  
  .productdisplay-right-stars {
    display: flex;
    align-items: center;
    margin-top: 14px;
    gap: 5px;
    font-size: 17px;
  }
  
  .productdisplay-right-prices {
    display: flex;
    align-items: center;
    margin: 30px 0;
    gap: 20px;
    font-size: 24px;
    font-weight: 700;
  }
  
  .productdisplay-right-prices-old {
    color: #7a7a7a;
    text-decoration: line-through;
  }
  .productdisplay-right-prices-new {
    color: #e94d14;
  }
  .productdisplay-right-sizes h1 {
    margin-top: 30px;
    color: #656565;
    font-size: 20px;
    font-weight: 600;
  }
  
  .productdisplay-right-sizes {
    display: flex;
    flex-direction: column;
  }
  
  .productdisplay-right-size {
    display: flex;
    gap: 30px;
    font-size: 19px;
    margin-top: 10px;
  }
  
  .productdisplay-right-size div {
    background: #f1f1f1;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #595959;
    margin: 0px 0;
    margin-bottom: 30px;
    cursor: pointer;
  }
  
  .productdisplay-right button {
    padding: 20px 40px;
    width: 230px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background: #e94d14;
    outline: none;
    border: none;
    margin-bottom: 30px;
    cursor: pointer;
  }
  
  .productdisplay-right-category {
    margin-top: 10px;
  }
  
  .productdisplay-right-category span {
    font-weight: 600;
  }
  
  @media (max-width: 1280px) {
    .productdisplay {
      margin: 0 30px;
    }
  
    .productdisplay-left {
      gap: 10px;
    }
  
    .productdisplay-img-list {
      gap: 10px;
    }
  
    .productdisplay-img-list img {
      height: 120px;
    }
  
    .productdisplay-big-img {
      width: auto;
      height: 510px;
    }
  
    .productdisplay-right {
      margin: 0 30px;
    }
  
    .productdisplay-right h1 {
      font-size: 24px;
    }
  
    .productdisplay-right-stars {
      gap: 3px;
      font-size: 13px;
    }
  
    .productdisplay-right-prices {
      margin: 20px 0;
      gap: 10px;
      font-size: 20px;
    }
  
    .productdisplay-right-sizes {
      margin-top: 10px;
      font-size: 18px;
    }
  
    .productdisplay-right-discription {
      font-size: 13px;
    }
  
    .productdisplay-right-sizes h1 {
      margin-top: 10px;
      font-size: 20px;
    }
  
    .productdisplay-right-size div {
      background: #f1f1f1;
      width: 40px;
      height: 40px;
      font-size: 15px;
    }
  
    .productdisplay-right button {
      padding: 15px 30px;
      width: 200px;
      font-size: 16px;
         margin-bottom: 10px;
    }
  
    .productdisplay-right-category {
      font-size: 15px;
    }
  }
  
  
  
  
  @media (max-width: 1024px) {
      .productdisplay {
        margin: 0 20px;
      }
    
  
    
      .productdisplay-img-list img {
        height: 100px;
      }
    
      .productdisplay-big-img {
        width: auto;
        height: 430px;
      }
    
      .productdisplay-right {
        margin: 0 20px;
      }
    
      .productdisplay-right h1 {
        font-size: 20px;
      }
    
      .productdisplay-right-stars {
        gap: 3px;
        font-size: 12px;
      }
    
      .productdisplay-right-prices {
        margin: 10px 0;
        gap: 5px;
        font-size: 18px;
      }
    
      .productdisplay-right-sizes {
        margin-top: 5px;
        font-size: 15px;
      }
    
      .productdisplay-right-discription {
        font-size: 13px;
      }
    
      .productdisplay-right-sizes h1 {
        margin-top: 5px;
        font-size: 20px;
      }
    
      .productdisplay-right-size div {
        background: #f1f1f1;
        width: 40px;
        height: 40px;
        font-size: 14px;
      }
    
      .productdisplay-right button {
        padding: 10px 20px;
        width: 150px;
        font-size: 16px;
           margin-bottom: 5px;
      }
    
      .productdisplay-right-category {
        font-size: 14px;
      }
  
  
    }
    
  
    
  
  @media (max-width: 800px) {
  
      .productdisplay {
          margin: 0 20px;
        }
      
  
        .productdisplay-left {
          gap: 5px;
          flex-direction: column-reverse;
          align-items: center;
        }
  
  .productdisplay-img-list{
      flex-direction: row;
      gap: 10px
        }
    
      .productdisplay-img-list img {
        height: 90px;
      }
    
      .productdisplay-big-img {
        width: auto;
        height: 390px;
      }
    
    }
    
  
  
    @media (max-width: 600px) {
  
  
      .productdisplay {
          flex-direction: column;
        }
  
        .productdisplay-left {
          gap: 10px;
          flex-direction: row;
          margin: auto;
   
        }
  
  
        .productdisplay-img-list{
          flex-direction: column;
          gap: 9px
  
            }
  
            .productdisplay-img-list img {
              height: 79px;
            }
            .productdisplay-big-img {
              width: auto;
              height: 342px;
            }
  
            .productdisplay-right {
              margin: 5px;
            }
  
            .productdisplay-right-sizes h1 {
              margin-top: 5px;
              font-size: 18px;
            }
  
            .productdisplay-right-stars img {
              width: 15px;
           
            }
  
    }
  