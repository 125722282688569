.shopcategory-banner{
    display: block;
    margin: 0px auto;
    width: 100%;
}

.shopcategory-banner img{
    width: 100%;
}


.shopcategory-indexSort{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 180px;
    padding: 20px 0;
}



.shopcategory-sort{
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #b8b8b8;
}


.shopcategory-indexSort p span{
    font-weight: 600;
}

.shopcategory-products{
    margin: 0px 180px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 40px;
}

.shopcategory-loadmore{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    height: 67px;
    width: 230px;
    border-radius: 75px;
    background: #ededed;
    color: #333;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
}