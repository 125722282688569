.descriptionbox {
    margin: 70px 170px;
  }
  
  .descriptionbox-navigator {
    display: flex;
  }
  .descriptionbox-nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 70px;
    border: 1px solid #d0d0d0;
  }
  
  .descriptionbox-nav-box .fade {
    background: #d9d9d9;
    color: #555;
  }
  .descriptionbox-description {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 45px;
    border: 1px solid #d0d0d0;
  
  }
  
  
  @media (max-width:1280px){
  
    .descriptionbox {
      margin: 20px 30px;
    }
  
  }
  
  
  @media (max-width:1024px){
  
    .descriptionbox {
      margin: 20px 20px;
    }
  
    .descriptionbox-description{
      max-width: 100%;
    }
  
    .productdisplay-right-size {
  
      gap: 20px;
  
  }
  
  }
  
  @media (max-width:800px){
  
    .descriptionbox-description {
  
      padding: 20px;
  
  }
  
  
    .descriptionbox {
      margin: 20px 20px;
    }
  
    .descriptionbox-description{
      max-width: 100%;
    }
  
    .productdisplay-right-size {
      gap: 10px;
  
  }
  
  .descriptionbox-description p{
    font-size: 13px;
    text-align: justify;
  }
  
  .descriptionbox-nav-box {
  
    width: 150px;
    height: 50px;
  
  }
  
  }